import React, { useState, useEffect } from "react";
import styles from "../styles/pages/_login.module.scss";
import { user } from "../api/user";

import TextInput from "../components/TextInput";
import Button from "../components/Button";

import logo from "../assets/images/biglogo.png";
import { useNavigate } from "react-router-dom";

export default function ForgetUserName() {
  const [email, setEmail] = useState(null);
  const navigate = useNavigate();

  const handleemailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSendButton = () => {
    forgetUsername();
  };

  const forgetUsername = async () => {
    let data = {
      email: email,
    };
    let cbData = await user.sendUsernName(data);

    if (cbData?.response?.data.status == "failed") {
      alert(cbData?.response?.data.message);
      return;
    }
    if (cbData?.data?.status == "success") {
        alert(cbData?.data?.message);
        navigate("/login");
        return;
    }
    
  };


  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <img src={logo} alt="" />
        <span className={styles.title}>Login</span>
        <div className={styles.signupForm}>
         {
            <div className={styles.inputContainer}>
             <TextInput label="Email" onChange={handleemailChange} />
           </div> 
        }

        <div className={styles.inputContainer}>
           <Button label="Send Username" onClick={handleSendButton} />
        </div>
        </div>
        <span className={styles.textContainer}>
        <div  
          onClick={() => navigate('/login')}
          style={{cursor: "pointer" , color: "blue"}}
        >
          Redirect to Login?
        </div> 
        </span>
      </div>
    </div>
  );
}
