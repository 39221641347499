import React, { useState, useEffect } from "react";
import "../styles/components/_navbar.scss";

import userIcon from "../assets/images/userIcon.png";
import Logo from "../assets/images/logo.png";
import { useNavigate, useLocation } from "react-router-dom";

export default function Navbar() {
  let location = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    console.log(location);
  }, [location, navigate]);

  const [active, setActive] = useState(false);
  const [currPath, setCurrPath] = useState("");

  useEffect(() => {
    if (!location) return;
    setCurrPath(location.pathname);
  }, [location]);

  return (
    <div className="nav-container">
      <div className="logo-container">
        <img src={Logo} alt="" />
        <div className="logo-text">Baker's Fifa Snipe Bot</div>
      </div>
      <div
        className={`nav-links-wrapper ${
          active ? "active scale-up-center" : ""
        }`}
      >
        <div className={`link ${currPath === "/" ? "active" : ""}`}>
          <a href="/">Home</a>
        </div>
        <div
          className={`link ${currPath.includes("/purchase") ? "active" : ""}`}
        >
          <a href="/purchase">Purchase</a>
        </div>
        <div className={`link ${currPath === "/faq" ? "active" : ""}`}>
          <a href="/faq">FAQ</a>
        </div>
        <div className={`link ${currPath === "/videos" ? "active" : ""}`}>
          <a href="/videos">Videos</a>
        </div>
        <div className={`link ${currPath === "/contact-us" ? "active" : ""}`}>
          <a href="/contact-us">Contact Us</a>
        </div>
        <div className={`link ${currPath === "/dashboard" ? "active" : ""}`}>
          <a href="/dashboard" style={{ marginRight: 20 }}>
            My Account
          </a>
          <div
            className={`profile-icon ${
              currPath.includes("/dashboard") ? "profileActive" : ""
            }`}
            onClick={() => (window.location.pathname = "dashboard")}
          >
            <img src={userIcon} alt="" />
          </div>
        </div>
      </div>
      <div
        className={`burger-menu ${active ? "menu-active" : ""}`}
        onClick={() => setActive(!active)}
      >
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
    </div>
  );
}
