import React, { useRef, useEffect } from "react";
import styles from "../styles/components/_textInput.module.scss";

export default function TextInput({
  onChange,
  label,
  defaultValue,
  question,
  type,
  icon,
  iconClickAction,
  disabled,
  loading,
}) {
  const inputRef = useRef();

  useEffect(() => {
    if (inputRef.current && defaultValue) inputRef.current.value = defaultValue;
  }, [defaultValue]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.labelArea}>
        <span className={styles.label}>{label}</span>
        <span className={styles.question}>{question}</span>
      </div>
      <div
        className={`${styles.inputContainer} ${
          disabled ? styles.disabled : ""
        }`}
      >
        <input
          ref={inputRef}
          type={type || "text"}
          defaultValue={defaultValue}
          onChange={onChange}
          disabled={disabled}
        />
        {icon && (
          <button
            className={styles.iconWrapper}
            onClick={iconClickAction}
            disabled={!defaultValue}
          >
            <img
              src={icon}
              alt=""
              className={loading ? styles.loadingIcon : null}
            />
          </button>
        )}
      </div>
    </div>
  );
}
