import axios from "axios";
import config from "../config";

export const payments = {
  getPaymentLink: () =>
    new Promise((resolve) => {
      axios
        .get(`${config.server}payments/link`)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    }),
  validatePayment: (token) =>
    new Promise((resolve) => {
      axios
        .get(`${config.server}payments/get/_id/` + token)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    }),
};
