import React, { useEffect, useState } from "react";
import TextInput from "../../components/TextInput";
import { payments } from "../../api/payments";
import config from "../../config";
import styles from "../../styles/screens/_createAccount.module.scss";
import { user } from "../../api/user";
import Button from "../../components/Button";
import logo from "../../assets/images/biglogo.png";
import copyIcon from "../../assets/images/copyIcon.svg";
import loadingIcon from "../../assets/images/loadingDark.svg";
import loadingWhite from "../../assets/images/loading.svg";

var Fakerator = require("fakerator");
var fakerator = Fakerator("de-DE");

export default function CreateAccount() {
  const [name, setName] = useState(null);
  const [instaUsername, setInstaUsername] = useState(null);
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [email, setEmail] = useState(null);
  const [valid, setValid] = useState(false);
  const [userCopied, setUserCopied] = useState(false);
  const [passCopied, setPassCopied] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleLogin = (cbData) => {
    localStorage.setItem("userData", JSON.stringify(cbData));
    localStorage.removeItem("paymentId");
    window.location.href = `${config.localUrl}/dashboard`;
  };
  const [btnLoading, setBtnLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [token, setToken] = useState(null);

  const handleRegister = async () => {
    if (!token) return;
    setBtnLoading(true);
    let data = {
      name: name,
      username: username,
      instagram_username: instaUsername,
      email: email,
      password: password,
      created_date: Date.now().toString(),
      token: token,
    };

    let cbData = await user.signup(data);

    console.log(cbData);
    if (cbData.status == "failed") {
      alert(cbData.message);
      setBtnLoading(false);
      return;
    }

    if (cbData) handleLogin(cbData);
  };

  const handleCopyUsername = () => {
    navigator.clipboard.writeText(username);
    setUserCopied(true);
  };
  const handleCopyPassword = () => {
    navigator.clipboard.writeText(password);
    setPassCopied(true);
  };

  function generateUsername(instaUsername) {
    // Define characters, symbols, and digits to use
    const characters = 'ABCDEFGHJKMNOPQRSTUVWXYZabcdefghjkmnopqrstuvwxyz';
    const digits = '0123456789';
    
    const allChars = characters + digits ;
    
    let newUsername = instaUsername[0] + '.' + instaUsername+ '-';
    
    for (let i = 0; i < 5; i++) {
      const randomChar = allChars[Math.floor(Math.random() * allChars.length)];
      newUsername += randomChar;
    }
  
    return newUsername;
  }

  function generateRandomPassword(length = 10) {
    const characters = 'ABCDEFGHJKMNPQRSTUVWXYZabcdefghjkmnpqrstuvwxyz0123456789';
    let password = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      password += characters[randomIndex];
    }
    
    return password;
  }

  useEffect(() => {
    if (name && instaUsername) setLoading(true);
    let uu = null;
    let pw = null;

    if (!name || !instaUsername) return;
    if (name.trim() != "" && instaUsername.trim() != "") {
      uu = generateUsername(instaUsername);
      pw = generateRandomPassword();
      setPassword(pw);
    }
    const getUsername = () =>
      new Promise((resolve) => {
        fetch(`${config.server}users/get/username/${uu}`)
          .then((res) => resolve(res))
          .catch((err) => err);
      });

    let timer = setTimeout(async () => {
      while (true) {
        let user = await getUsername();
        if (!user?.data) {
          setUsername(uu);
          setLoading(false);
          break;
        }
      }
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, [name, instaUsername]);

  useEffect(() => {
    console.log(fakerator);
  }, [fakerator]);

  useEffect(() => {
    if (!name || !instaUsername || !email) return setValid(false);
    setValid(
      name.trim() !== "" && email.trim() !== "" && instaUsername.trim() !== ""
    );
  }, [name, email, instaUsername]);

  useEffect(() => {
    const validateToken = async (id) => {
      let result = await payments.validatePayment(id);
      console.log(result);
      if (!result?.data?.data?._id)
        return (window.location.href = config.localUrl + "/purchase");

      setPageLoading(false);
      setToken(result?.data?.data?._id);
    };

    let pathname = window.location.pathname;
    if (pathname === "purchase") return;
    let param = pathname.replace("/purchase/");
    let orderId = param.split("=");

    if (!orderId[1]) return;

    validateToken(orderId[1]);

    setPageLoading(true);
  }, []);

  return (
    <div className={styles.card}>
      {pageLoading && (
        <div className={styles.pageLoading}>
          <img src={loadingWhite} className={styles.loadingIcon} />
        </div>
      )}
      <div className={styles.banner}></div>
      <div className={styles.content}>
        <div className={styles.logo}>
          <img src={logo} alt="" />
          <span className={styles.caption}>Baker's Fifa Snipe Bot</span>
        </div>
        <div className={styles.textCaption}>
          Let’s proceed with your account.
        </div>
        <div className={styles.inputRow}>
          <TextInput
            onChange={(e) => setName(e.target.value)}
            label="Your Name"
            type="text"
          />
          <TextInput
            onChange={(e) => setInstaUsername(e.target.value)}
            label="Instagram Username"
            type="text"
            question="Why this?"
          />
        </div>
        <div className={styles.inputRow}>
          <TextInput
            onChange={(e) => setEmail(e.target.value)}
            label="Email Address"
            type="email"
          />
        </div>
        <div className={styles.inputRow}>
          <TextInput
            onChange={(e) => setName(e.target.value)}
            label="Username"
            type="text"
            icon={loading ? loadingIcon : copyIcon}
            loading={loading}
            iconClickAction={loading ? null : handleCopyUsername}
            defaultValue={username}
            disabled={true}
          />

          <TextInput
            onChange={(e) => setName(e.target.value)}
            label="Password"
            type="text"
            icon={copyIcon}
            iconClickAction={handleCopyPassword}
            defaultValue={password}
            disabled={true}
          />
        </div>
        <div className={`${styles.inputRow} ${styles.highlight}`}>
          Please remember your username and password, you will not be able to
          use your bot without having your username and password. Write it down
          somewhere or take a picture
        </div>
        <div className={styles.paypalButton}>
          <Button
            label="Buy & Create Account"
            disabled={!valid}
            loading={btnLoading}
            onClick={handleRegister}
          />
        </div>
      </div>
    </div>
  );
}
