import React, { useState, useEffect } from "react";
import styles from "../../styles/screens/_changePass.module.scss";
import TextInput from "../../components/TextInput";
import Button from "../../components/Button";

import { user } from "../../api/user";

export default function ChangePassword() {
  const [currentPass, setCurrentPass] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [confirmPass, setConfirmPass] = useState(null);
  const [valid, setValid] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    if (!valid) return;

    let localData = localStorage.getItem("userData");
    if (!localData) return;

    let jsonified = JSON.parse(localData);
    let data = {
      id: jsonified.data.data._id,
      old_password: currentPass,
      password: newPassword,
      confirm_password: confirmPass,
    };

    let cbData = await user.changePassword(data);
    setLoading(false);
    if (cbData?.data?.status === "success") {
      setCurrentPass("");
      setNewPassword("");
      setConfirmPass("");
      setError("");
      alert("Password Changed Successfully");
      return;
    }
    if (cbData?.status === "failed") alert(cbData.message);
  };

  useEffect(() => {
    if (!currentPass || !newPassword || !confirmPass) return setValid(false);
    if (
      confirmPass.trim() === "" ||
      newPassword.trim() === "" ||
      confirmPass.trim() === ""
    )
      return setValid(false);
    if (newPassword !== confirmPass) return setValid(false);
    return setValid(true);
  }, [currentPass, newPassword, confirmPass]);

  return (
    <div className={styles.passContainer}>
      <span>Change Password</span>
      <div className={styles.passwordForm}>
        <TextInput
          onChange={(e) => setCurrentPass(e.target.value)}
          label="Current Password"
          type="password"
        />
        <div className={styles.flexBox}>
          <TextInput
            onChange={(e) => setNewPassword(e.target.value)}
            label="New Password"
            type="password"
          />
          <TextInput
            onChange={(e) => setConfirmPass(e.target.value)}
            label="Confirm Password"
            type="password"
          />
        </div>
        <Button
          onClick={handleClick}
          label="Change Password"
          disabled={!valid}
          loading={loading}
        />
        <span className={styles.error}>{error}</span>
      </div>
    </div>
  );
}
