import React from "react";
import { useState, useEffect } from "react";

import { user } from "../api/user";
import config from "../config";
import NavButton from "./NavButton";
import styles from "../styles/components/_sidebar.module.scss";

import profileIcon from "../assets/images/profileIcon.png";
import dashIcon from "../assets/images/dashIcon.svg";
import keyIcon from "../assets/images/keyIcon.svg";
import closeIcon from "../assets/images/closeIcon.svg";
import LogoutIcon from "../assets/images/logout.svg";


export default function Sidebar({
  screen,
  setScreen,
  isMobile,
  isOpen,
  setIsOpen,
}) {
  const [userName, setUsername] = useState("");

  const [active, setActive] = useState(false);
  const [sidebarStyles, setSidebarStyles] = useState(styles.container);

  const goBack = () => {
    window.location.href = `${config.localUrl}/login`;
  };

  useEffect(() => {
    const getUser = async (id) => {
      let validation = await user.getUserById(id);
      console.log(validation);
      if (!validation) return goBack();
      if (!validation?.data?.data?._id) return goBack();
      setActive(validation.data.data.valid);
    };

    let userData = localStorage.getItem("userData");
    if (!userData) return goBack();

    const jsonified = JSON.parse(userData);
    getUser(jsonified.data.data._id);
    setUsername(jsonified.data.data.username);
  }, []);

  useEffect(() => {
    if (isMobile) {
      if (isOpen) {
        setSidebarStyles(`${styles.container} ${styles.mobile} ${styles.open}`);
      } else {
        setSidebarStyles(`${styles.container} ${styles.mobile}`);
      }
    } else {
      setSidebarStyles(styles.container);
    }
    console.log("isOpen: ", isOpen);
  }, [isMobile, isOpen, setIsOpen]);

  return (
    <div className={sidebarStyles}>
      {isMobile && (
        <div className={styles.closeButton}>
          <button onClick={() => setIsOpen(false)}>
            <img src={closeIcon} alt="" />
          </button>
        </div>
      )}
      <div className={styles.userInfo}>
        <img src={profileIcon} alt="" />
        <div className={styles.nameContainer}>
          <span className={styles.label}>Username</span>
          <span className={styles.primaryText}>{userName}</span>
        </div>
        <div className={styles.statusContainer}>
          <span className={styles.label}>Status</span>
          <span className={styles.highlightText}>
            {active ? "Active" : "Banned"}
          </span>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <NavButton
          icon={dashIcon}
          onClick={() => setScreen("dashboard")}
          label="Dashboard"
          active={screen === "dashboard"}
        />
        <NavButton
          icon={keyIcon}
          onClick={() => setScreen("password")}
          label="Change Password"
          active={screen === "password"}
        />

        <NavButton
          icon= {LogoutIcon}
          onClick={() => {
            user.logout({
            username: userName
          })
          localStorage.removeItem("userData");
          window.location.href = "/login"
        }
        }
          label="Logout"
        />

      </div>
    </div>
  );
}
