import React from "react";
import styles from "../styles/components/_footer.module.scss";

import instaIcon from "../assets/images/instaIcon.png";
import tikTokIcon from "../assets/images/tiktokIcon.png";

export default function Footer() {
  return (
    <div className={styles.footer}>
      <div className={styles.hairline}></div>
      <div className={styles.links}>
        <div className={styles.link}>
          <a
            href="https://www.instagram.com/fifasnipebot/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={instaIcon} alt="" />
            Fifasnipebot
          </a>
        </div>
        <div className={styles.link}>
          <a
            href="https://www.tiktok.com/@fifasnipebot"
            target="_blank"
            rel="noreferrer"
          >
            <img src={tikTokIcon} alt="" />
            Fifasnipebot
          </a>
        </div>
      </div>
    </div>
  );
}
