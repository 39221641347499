import axios from "axios";
import config from "../config";

export const user = {
  signin: (data) =>
    new Promise((resolve) => {
      axios
        .post(`${config.server}users/sigin`, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          if (error?.response?.data?.message === "user not found") {
            resolve({ status: "failed", message: "User not found." });
          } else if (error?.response?.data?.message === "incorrect password") {
            resolve({ status: "failed", message: "Incorrect Password." });
          }
          console.log(error);
        });
    }),
  signup: (data) =>
    new Promise((resolve) => {
      axios
        .post(`${config.server}users/signup`, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          resolve(error.response.data);
        });
    }),
  changePassword: (data) =>
    new Promise((resolve) => {
      axios
        .patch(`${config.server}users/update/password`, data, {
          withCredentials: true,
        })
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve(error.response.data);
          console.log(error);
        });
    }),
  getUserById: (data) =>
    new Promise((resolve) => {
      axios
        .get(`${config.server}users/get/_id/${data}`)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    }),
    forget: (data) =>
      new Promise((resolve) => {
        axios
          .post(`${config.server}users/forget`, data)
          .then((response) => {
            resolve(response);
          })
          .catch(function (error) {
            console.log(error);
            resolve(error);
          });
    }),
    validate_otp: (data) =>
      new Promise((resolve) => {
        axios
          .post(`${config.server}users/validate-otp`, data)
          .then((response) => {
            resolve(response);
          })
          .catch(function (error) {
            console.log(error);
            resolve(error);
          });
      }),
      reset_password: (data) =>
        new Promise((resolve) => {
          axios
            .post(`${config.server}users/reset-password`, data)
            .then((response) => {
              resolve(response);
            })
            .catch(function (error) {
              console.log(error);
              resolve(error);
            });
        }),
        logout: (data) =>
          new Promise((resolve) => {
            axios
              .post(`${config.server}users/logout`, data)
              .then((response) => {
                resolve(response);
              })
              .catch(function (error) {
                console.log(error);
                resolve(error);
              });
          }),
          sendUsernName: (data) =>
            new Promise((resolve) => {
              axios
                .post(`${config.server}users/send-username`, data)
                .then((response) => {
                  resolve(response);
                })
                .catch(function (error) {
                  console.log(error);
                  resolve(error);
                });
            }),

       
};
