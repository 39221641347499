import React from "react";
import { useState, useEffect } from "react";
import Modal from "react-modal";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { payments } from "../../api/payments";
import styles from "../../styles/screens/_onboarding.module.scss";
import Checkbox from "react-custom-checkbox";
import Button from "../../components/Button";
import tosMarkdown from "../../constants/tos";

import * as Icon from "react-icons/fi";

import logo from "../../assets/images/biglogo.png";
import closeButton from "../../assets/images/closeIcon.svg";

export default function Onboarding({ setPaymentSuccess }) {
  const [checked, setChecked] = useState(false);
  const handleCheckbox = (_checked, e) => {
    setChecked(_checked);
  };
  const [modalIsOpen, setIsOpen] = useState(false);

  const customStyles = {
    content: {
      marginTop: 60,
      padding: 30,
      width: window.outerWidth < 768 ? "100%" : "60%",
      left: "50%",
      transform: "translateX(-50%)",
    },
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const [loading, setLoading] = useState(false);

  const handleGoToPayment = async () => {
    setLoading(true);
    let response = await payments.getPaymentLink();
    if (response) window.location.href = response.data.data;
  };

  useEffect(() => {
    let pathname = window.location.pathname;
    let param = pathname.replace("/purchase/");
    let orderId = param.split("=");

    if (!orderId[1]) return;
    localStorage.setItem("paymentId", orderId[1]);
    setPaymentSuccess(true);
  }, [setPaymentSuccess]);

  useEffect(() => {
    let pathname = window.location.pathname;
    if (pathname === "/purchase" || pathname === "/purchase/") {
      let oldOrderId = localStorage.getItem("paymentId");
      if (oldOrderId)
        window.location.pathname = "/purchase/order_id=" + oldOrderId;
    }
  }, []);

  return (
    <div className={styles.card}>
      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Terms of Service"
      >
        <button
          onClick={() => setIsOpen(false)}
          style={{
            position: "fixed",
            right: 20,
            top: 20,
            cursor: "pointer",
            background: "#494949",
            border: "none",
            outline: "none",
            cursor: "pointer",
          }}
        >
          <img src={closeButton} alt="" />
        </button>
        <ReactMarkdown children={tosMarkdown} remarkPlugins={[remarkGfm]} />
      </Modal>
      <div className={styles.banner}></div>
      <div className={styles.content}>
        <div className={styles.logo}>
          <img src={logo} alt="" />
        </div>
        <span className={styles.caption}>Baker's Fifa Snipe Bot</span>
        <div className={styles.hairline}></div>
        <div className={styles.textContent}>
          <div className={styles.textCaption}>Terms of Service</div>
          <div className={styles.textParagraph}>
            Ready to purchase your Baker’s Fifa Snipe Bot? You are one click
            away!
            <br />
            Before you complete your purchase, please take a moment to read
            through our Terms and Conditions.
            <br />
            <br />
            We really appreciate your business and we know you will love our
            product.
            {/* <span className={styles.link}>Continue reading...</span> */}
          </div>
        </div>
        <div className={styles.agreement}>
          <Checkbox
            icon={<Icon.FiCheck color="#FE5601" size={14} />}
            name="my-input"
            borderColor="#FE5601"
            style={{ cursor: "pointer" }}
            labelStyle={{ marginLeft: 5, userSelect: "none" }}
            onChange={handleCheckbox}
          />
          <div className={styles.checkboxLabel}>
            I agree to the{" "}
            <span className={styles.link} onClick={() => setIsOpen(true)}>
              Terms and Conditions{" "}
            </span>
            and would like to pay forward
          </div>
        </div>

        <div className={styles.paypalButton}>
          <Button
            onClick={handleGoToPayment}
            label="Go to Checkout"
            disabled={!checked}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
}
