import React from "react";
import styles from "../styles/pages/_contactUs.module.scss";
import "../styles/components/markdownStyles.scss";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import contactUsImg from "../assets/images/contactUs.png";
import instaIconBig from "../assets/images/instaIconBig.png";

export default function ContactUs() {
  const markdown = `# Contact Us
  > Thank you for considering doing business with us. If you have any questions or concerns, please don't hesitate to contact us on Instagram. We are always happy to help

  &nbsp;
  &nbsp;
  &nbsp;  
  
  [![Instagram](${instaIconBig}) **Fifasnipebot**](https://www.instagram.com/fifasnipebot/) 

  &nbsp;
  &nbsp;
  &nbsp;

  ## **Interested in making money?**
  > If you are interested in making money, we have an opportunity for you. Send us a message on Instagram mentioning that you are interested in making money and working with us and you came from our website. We will get back to you as soon as possible with more information. Don't miss out on this opportunity to increase your income.


  `;
  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <div className="row">
          <div className="col-md-6 contactImage">
            <img src={contactUsImg} alt="" className={styles.contactUsImg} />
          </div>
          <div className="col-md-6">
            <ReactMarkdown children={markdown} remarkPlugins={[remarkGfm]} />
          </div>
        </div>
      </div>
    </div>
  );
}
