import React, { useState } from "react";

import Onboarding from "../screens/purchase/Onboarding";
import CreateAccount from "../screens/purchase/CreateAccount";

import styles from "../styles/pages/_purchase.module.scss";

export default function Purchase() {
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {!paymentSuccess && !loggedIn && (
          <Onboarding setPaymentSuccess={setPaymentSuccess} />
        )}
        {paymentSuccess && !loggedIn && (
          <CreateAccount setLoggedIn={setLoggedIn} />
        )}
      </div>
    </div>
  );
}
