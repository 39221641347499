"use strict";
// require('dotenv').config();

module.exports = {
  localUrl: "https://fifasnipebot.com",
  local: "dashboard",
  development: true ? (true === "true" ? true : false) : false,
  server: "https://dashboards-server-gamma.vercel.app/api/v1/"
    ? "https://dashboards-server-gamma.vercel.app/api/v1/".slice(-1) === "/"
      ? "https://dashboards-server-gamma.vercel.app/api/v1/"
      : "https://dashboards-server-gamma.vercel.app/api/v1/" + "/"
    : null,
  video_server: "https://video-server-six.vercel.app/api/v1/"
    ? "https://video-server-six.vercel.app/api/v1/".slice(-1) === "/"
      ? "https://video-server-six.vercel.app/api/v1/"
      : "https://video-server-six.vercel.app/api/v1/" + "/"
    : null,
  stripe_pk:
    "pk_live_51LyNBFGRbJh7WS2rijfj3lPdZcmgvVDdpBz8pzpY158aRxeq4iGsjRgfW5ghr2yD283OOjIBEAnHl2q0RTUmjzhW00CKNwUKXO",
};


// module.exports = {
//   localUrl: "http://localhost:3000",
//   local: "dashboard",
//   development: true ? (true === "true" ? true : false) : false,
//   server: "http://localhost:9000/api/v1/"
//     ? "http://localhost:9000/api/v1/".slice(-1) === "/"
//       ? "http://localhost:9000/api/v1/"
//       : "http://localhost:9000/api/v1/" + "/"
//     : null,
//   stripe_pk:
//     "pk_test_51KcufgBkcOv5n76xvtVDbW5LcWm7U9Q0iQn4zyOQXcp2oThUUBbXKQFwtMcv1laIpcMHBqhR6zCYSYVQNRCndMb100jLPPO7eR",
// };
