import React from "react";
import styles from "../styles/pages/_faq.module.scss";
import "../styles/components/markdownStyles.scss";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

export default function Faq() {
  const markdown = `# **Frequently asked questions (FAQ)** 
  ### Still have more questions? Feel free to contact us
  &nbsp;  
  &nbsp;  

  ## **What is Sniping?**
  > You may have seen on Fifa  where a player who might sell for 15k gets listed for 12k, or 10k, or even a player that is worth 400k get listed for 100k or lower. Snipping is basically being able to purchase those players that get listed for cheap. It’s called snipping because you are competing with millions of users.

  
  &nbsp;  
  &nbsp;  
  ## **What is Baker’s Fifa snipe bot?**
  > Everyday someone is listing a player for at a cheaper price. However, you cannot manually search all day for a player yourself. Our bot does that process for you. Simply tell the bot who you want and at what price, and the bot will search for that player and list the player on the market for you at the price you tell it. You can tell the bot to keep player as well, you do not necessarily have to sell the player.

  &nbsp;  
  &nbsp;  
  ## **How much coins can i expect to make?**
  > Speaking to customers of mine, in the most worst case scenario, you will make 100k a week, and that is if you maybe using the bot only 1-3 times a week. You should not expect to make less than 100k a week. So in the most worst case scenario, you will make 400k a month. Keep in mind, that is the worst case scenario.
  
  &nbsp;  
  &nbsp;  
  ## **How much coins do i need to start and who do i snipe?**
  > You can start with as little as 5k. That’s the beauty of the bot, there is no minimum. You can easily work your way up. If you are not sure who to snipe, don’t worry. We give you tips to get started and you’ll be able to get an idea. We also offer a premium discord where we tell you who to snipe and what price. 

  &nbsp;  
  &nbsp;  
  ## **How do I set up the bot & How many accounts can i use the bot on?**
  > When you purchase, a video tutorial helping you set up the bot is provided. After that, one click of a button and the bot will activate. You need access to the web market to use the bot. The bot is meant to be used on one account and on one device. Breaking this rule will result on your bot being suspended

  &nbsp;  
  &nbsp;  
  ## **Can you get banned using the bot?**
  > Yes, there is a chance of a ban. However, we have many ways to decrease this from happening. The auto stop feature lets you stop the bot after a certain amount of searches, making it hard for EA to detect you using a bot. We also have rules which if you follow, you significantly decrease the chance of ban from the web market.


  `;

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <ReactMarkdown children={markdown} remarkPlugins={[remarkGfm]} />
      </div>
    </div>
  );
}
