import React from "react";
import styles from "../styles/components/_navButton.module.scss";

export default function NavButton({ icon, onClick, label, active }) {
  return (
    <div
      className={
        active
          ? `${styles.active} ${styles.buttonWrapper}`
          : styles.buttonWrapper
      }
    >
      <button className={styles.button} onClick={onClick}>
        <img className={styles.icon} src={icon} alt="" />
        {label}
      </button>
    </div>
  );
}
