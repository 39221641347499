import React from "react";
import styles from "../styles/components/_button.module.scss";
import loadingIcon from "../assets/images/loading.svg";

export default function Button({ onClick, label, disabled, loading }) {
  return (
    <div className={styles.buttonWrapper}>
      <button onClick={onClick} disabled={disabled || loading}>
        {!loading && label}
        {loading && <img className={styles.loadingIcon} src={loadingIcon} />}
      </button>
    </div>
  );
}
