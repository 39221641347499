import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Purchase from "./pages/Purchase";
import Home from "./screens/dashboard/Home";
import HomePage from "./pages/HomePage"; //site home page
import Faq from "./pages/FAQ";
import Videos from "./pages/Videos";
import ContactUs from "./pages/ContactUs";
import Login from "./pages/Login";
import ForgetPassword from "./pages/ForgetPassword";
import ForgetUserName from "./pages/ForgetUsername";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/purchase" element={<Purchase />}>
            <Route path=":order_id" element={<Purchase />} />
          </Route>
          <Route path="/dashboard" element={<Home />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/videos" element={<Videos />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forget" element={<ForgetPassword />} />
          <Route path="/forget-username" element={<ForgetUserName />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
