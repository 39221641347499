import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import Dashboard from "./Dashboard";
import ChangePassword from "./ChangePassword";

import styles from "../../styles/screens/_home.module.scss";

export default function Home() {
  const [screen, setScreen] = useState("dashboard");
  const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.outerWidth < 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
      console.log(window.outerWidth);
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // console.log("isOpen: ", isOpen);
  }, [isOpen]);

  return (
    <div className="container" style={{padding: isMobile ? 0 : "20px"}}>
      <div className={styles.card}>
        <Sidebar
          screen={screen}
          setScreen={setScreen}
          isMobile={isMobile}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
        {screen === "dashboard" && (
          <Dashboard
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            isMobile={isMobile}
          />
        )}
        {screen === "password" && <ChangePassword />}
      </div>
    </div>
  );
}
