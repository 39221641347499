import React, { useState, useEffect } from "react";
import styles from "../styles/pages/_login.module.scss";
import { user } from "../api/user";

import TextInput from "../components/TextInput";
import Button from "../components/Button";

import logo from "../assets/images/biglogo.png";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null); 
  const navigate = useNavigate();

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleLoginButton = () => {
    login();
  };

  const login = async () => {
    let data = {
      username: username,
      password: password,
    };
    let cbData = await user.signin(data);

    if (cbData.status == "failed") {
      alert(cbData.message);
      return;
    }
    if (cbData) {
      localStorage.setItem("userData", JSON.stringify(cbData));
      window.location.pathname = "dashboard";
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <img src={logo} alt="" />
        <span className={styles.title}>Login</span>
        <div className={styles.signupForm}>
          <div className={styles.inputContainer}>
            <TextInput label="Username" onChange={handleUsernameChange} />
          </div>
          <div className={styles.inputContainer}>
            <TextInput
              label="Password"
              type="password"
              onChange={handlePasswordChange}
            />
          </div>
          <div className={styles.inputContainer}>
            <Button label="Login" onClick={handleLoginButton} />
          </div>
        </div>
        <span className={styles.textContainer}>
        <div  
          onClick={() => navigate('/forget')}
          style={{cursor: "pointer" , color: "blue"}}
        >
          Forgot password?
        </div>
        </span>
        <span className={styles.textContainer}>
        <div  
          onClick={() => navigate('/forget-username')}
          style={{cursor: "pointer" , color: "blue"}}
        >
          Forgot username?
        </div>
        </span>
      </div>
    </div>
  );
}
