import React from "react";
import styles from "../styles/pages/_homepage.module.scss";

import screenshot from "../assets/images/screenshot.png";
import heroRectangles from "../assets/images/heroRectangles.svg";
import scrollDown from "../assets/images/scrollDown.svg";

import Button from "../components/Button";

export default function HomePage() {
  const handleReadmore = () => {
    const readMore = document.getElementById("read-more");
    readMore.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        <div className={`${styles.hero}`}>
          <div className="container">
            <div className={styles.row}>
              <img src={heroRectangles} alt="" className={styles.rectangles} />
              <div className={`col-md-7 ${styles.heroTitle}`}>
                <span className={styles.bakers}>Baker's</span>
                <span className={styles.title}>Fifa snipe bot</span>
                <span className={styles.textContent}>
                  Tired of playing against insane teams? Tired of wasting money
                  on Fifa points? Tired of relying on fut champs and division
                  rival rewards to build your ultimate team? Wish you could play
                  with your favourite players? Mbappe? Cruyff? Neymar?
                </span>
                <div className={styles.buttonContainer}>
                  <Button label="Read more" onClick={handleReadmore} />
                </div>
              </div>
              <div className={`${styles.center} col-md-5`}>
                <img src={screenshot} alt="" className={styles.screenshot} />
              </div>
            </div>
          </div>
          <img src={scrollDown} className={styles.scrollDown} alt="" />
        </div>
      </div>
      <div className={styles.orangeContent} id="read-more">
        <div className="container">
          <div className={styles.row}>
            <span className={styles.title}>THE ANSWER IS SIMPLE</span>
          </div>
          <div className={styles.row}>
            <span className={styles.textContent}>
              Baker’s Fifa Snipe Bot is a bot designed to help you snipe players
              on the market at a cheaper price, making it extremely easy for you
              to make millions of coins.
            </span>
          </div>
          <div className={styles.row}>
            <span className={styles.footerText}>
              Make sure to checkout the “How it Works” & “Videos” section to
              learn more about our bot
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
