import React, { useState, useEffect } from "react";
import styles from "../styles/pages/_login.module.scss";
import { user } from "../api/user";

import TextInput from "../components/TextInput";
import Button from "../components/Button";

import logo from "../assets/images/biglogo.png";
import { useNavigate } from "react-router-dom";

export default function ForgetPassword() {
  const [email, setEmail] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [otp, setOTP] = useState(null);
  const [showEmailField, setShowEmailField] = useState(true);
  const [showOTPField, setShowOTPField] = useState(null);
  const [showPasswordField, setShowPasswordField] = useState(null);
  const navigate = useNavigate();

  const handleemailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };
  const handleLoginButton = () => {
    if(showEmailField){
        forget();
        return;
    }
    if(showOTPField){
        validateOTP();
        return;
    }
    if(showPasswordField){
        reset_password();
        return
    }
    alert("Please refresh page and try again");
  };

  const handleOTP = (e) => {
    setOTP(e.target.value);
  };

  const forget = async () => {
    let data = {
      email: email,
    };
    let cbData = await user.forget(data);

    if (cbData?.response?.data.status == "failed") {
      alert(cbData?.response?.data.message);
      return;
    }
    if (cbData?.data?.status == "success") {
        alert(cbData?.data?.message);
        setShowEmailField(false);
        setShowOTPField(true);
        return;
    }
    
  };

  const validateOTP = async () => {
    let data = {
      email: email,
      otp : parseInt(otp)
    };
    let cbData = await user.validate_otp(data);
   
    if (cbData?.response?.data.status == "failed") {
        alert(cbData?.response?.data.message);
        return;
    }
    if (cbData?.data?.status == "success") {
        setShowOTPField(false)
        setShowPasswordField(true)
        // alert(cbData?.data?.message);
        return;
    }
    
  };


  const reset_password = async () => {
    let data = {
      email: email,
      password : newPassword
    };

    if(newPassword !== confirmPassword){
      alert("Password doesnot match!!");
      return
    }

    let cbData = await user.reset_password(data);

    if (cbData?.response?.data.status == "failed") {
        alert(cbData?.response?.data.message);
        return;
      }
    if (cbData?.data?.status == "success") {
        alert(cbData?.data?.message);
        window.location.pathname = "login";
        return;
    }
    
  };

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <img src={logo} alt="" />
        <span className={styles.title}>Login</span>
        <div className={styles.signupForm}>
         {
            showEmailField && <div className={styles.inputContainer}>
             <TextInput label="Email" onChange={handleemailChange} />
           </div> 
        }
        {
            showPasswordField &&  <>
                <div className={styles.inputContainer}>
                    <TextInput
                    label="New Password"
                    type="password"
                    onChange={handleNewPasswordChange}
                    />
                </div>
                <div className={styles.inputContainer}>
                    <TextInput
                    label="Confirm Password"
                    type="password"
                    onChange={handleConfirmPasswordChange}
                    />
                </div>
            </>
        }
        {
            showOTPField && <div className={styles.inputContainer}>
            <TextInput
            label="OTP"
            type="text"
            onChange={handleOTP}
            />
        </div>
        }
          <div className={styles.inputContainer}>
            <Button label="Reset Password" onClick={handleLoginButton} />
          </div>
        </div>
        <span className={styles.textContainer}>
        <div  
          onClick={() => navigate('/login')}
          style={{cursor: "pointer" , color: "blue"}}
        >
          Redirect to Login?
        </div> 
        </span>
      </div>
    </div>
  );
}
