const tosMarkdown = `# Terms of Service

Welcome to Baker's Fifa Snipe Bot! By accessing or using our services, you agree to be bound by the following terms and conditions (the "Terms"). If you do not agree to all of the terms and conditions of this agreement, do not use our services.
&nbsp;
&nbsp;
## Acceptance of Terms
By accessing or using the Baker's Fifa Snipe Bot services, including but not limited to the website and any other products or services provided by Baker's Fifa Snipe Bot (collectively, the "Services"), you agree to be bound by these terms and conditions (the "Terms"). If you do not agree to all of the terms and conditions of this agreement, do not use the Services.

## Changes to the Terms
We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will try to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.

## Account Creation
In order to use the features offered by Baker's Fifa Snipe Bot, you will need to create an account. You must provide accurate and complete information when creating your account. You are solely responsible for the activity that occurs on your account, and you must keep your account password secure. You must notify us immediately of any breach of security or unauthorized use of your account.

## Use of the Services
You may use the Services only if you can form a binding contract with Baker's Fifa Snipe Bot and only in compliance with these Terms and all applicable local, state, national, and international laws, rules and regulations.

## Intellectual Property
The content and materials on Baker's Fifa Snipe Bot, including but not limited to text, graphics, images, logos, and software, are the property of Baker's Fifa Snipe Bot or its licensors and are protected by copyright and trademark laws. You are granted a limited, non-exclusive, non-transferable license to access and use the Services for your personal use only. Any other use of the materials on Baker's Fifa Snipe Bot, including but not limited to the modification, distribution, or reproduction of the content, is strictly prohibited.

## Disclaimer of Warranties
Baker's Fifa Snipe Bot provides the Services on an "as is" and "as available" basis. We make no representations or warranties of any kind, express or implied, as to the operation of the Services or the information, content, materials, or products included on the Services. We do not warrant that the Services will be uninterrupted or error-free, and we will not be liable for any interruptions or errors.

## Limitation of Liability
Baker's Fifa Snipe Bot will not be liable for any damages of any kind arising from the use of the Services, including but not limited to direct, indirect, incidental, punitive, and consequential damages. In the event that you are banned from using the Services or the EA platform as a result of your actions or the actions of a third party, Baker's Fifa Snipe Bot will not be held liable for any such ban.

## Indemnification
You agree to indemnify and hold Baker's Fifa Snipe Bot and its affiliates, officers, agents, and employees harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your use of the Services, your violation of these Terms, or your violation of any rights of another.

## Governing Law
These Terms and your use of the Services will be governed by and construed in accordance with the laws of Canada, without giving effect to any principles of conflicts of law.

## Dispute Resolution
Any dispute arising out of or relating to these Terms or the Services shall be resolved through binding arbitration in accordance with the Commercial Arbit. Baker’s Fifa Snipe Bot is willing to help with anything related to the bot but is not held liable for any bans or misunderstandings prior to purchase.

## Refunds
Baker's Fifa Snipe Bot does not offer refunds under any circumstances. All sales are final. There is no scenario where we offer refunds.

## Trials
Baker's Fifa Snipe Bot does not offer trials or free use of the Services. All users are required to purchase a subscription or pay for use of the Services.

## Termination of Services
We reserve the right, at our sole discretion, to terminate or suspend your access to the Services at any time and for any reason without notice.



## Third-Party Links
The Services may contain links to third-party websites or services that are not owned or controlled by Baker's Fifa Snipe Bot. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or services.

## Disclaimer of Endorsement
The inclusion of any link on the Services does not imply endorsement by Baker's Fifa Snipe Bot of the linked website or service.

## User Content
Baker's Fifa Snipe Bot may allow users to post, upload, or transmit content, including but not limited to text, images, and videos (collectively, "User Content"). By posting, uploading, or transmitting User Content to the Services, you grant Baker's Fifa Snipe Bot a worldwide, non-exclusive, royalty-free, perpetual, irrevocable, and fully sublicensable license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such User Content for any purpose.

## Responsibility for User Content
You are solely responsible for the User Content that you post, upload, or transmit to the Services. You agree that you will not post, upload, or transmit any User Content that: (a) infringes any third-party intellectual property rights; (b) is unlawful, obscene, defamatory, libelous, threatening, or harassing; (c) contains viruses or other malicious code; or (d) violates any applicable laws or regulations.

## Removal of User Content
We reserve the right, at our sole discretion, to remove any User Content from the Services for any reason.
`;

export default tosMarkdown;