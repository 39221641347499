import React, { useState, useEffect, useRef } from "react";
import styles from "../../styles/screens/_dashboard.module.scss";
import Button from "../../components/Button";
import config from "../../config";
import axios from "axios";
import fileDownload from "js-file-download";
import ReactPlayer from "react-player";

export default function Dashboard({ isMobile, isOpen, setIsOpen }) {
  const [videoDimensions, setVideoDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [isSafari, setIsSafari] = useState(false);
  const [videoFile, setVideoFile] = useState(null);

  const handleDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  useEffect(() => {
    const handleResize = () => {
      if (isMobile) {
        setVideoDimensions({
          ...{
            width: window.outerWidth - 40,
            height: window.outerWidth / 1.77,
          },
        });
      } else {
        setVideoDimensions({ ...{ width: 640, height: 360 } });
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [isMobile]);

  useEffect(() => {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("safari") != -1) {
      if (ua.indexOf("chrome") > -1) {
        setIsSafari(false);
        console.log("Chrome");
      } else {
        setIsSafari(true);
        console.log("Safari");
      }
    }
  }, []);

  useEffect(() => {
    const fetchVideo = async () => {
      let response = await fetch(
        "https://raw.githubusercontent.com/fifasnipebot/bot/assets/tutorial.mp4"
      );
      let data = await response.blob();
      let metadata = {
        type: "application/octet-stream",
      };
      let file = new File([data], "tutorial.mp4", metadata);
      setVideoFile(URL.createObjectURL(file));
    };
    fetchVideo();
  }, []);

  const handleOpenSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={styles.dashContainer}>
      {isMobile && (
        <div className={styles.mobileMenuSwitch}>
          <button onClick={handleOpenSidebar}>
            <div className={styles.line} />
            <div className={styles.line} />
            <div className={styles.line} />
          </button>
        </div>
      )}
      <span>
        To begin using the bot, please click download below and follow the video
        tutorial provided.
      </span>
      <div className={styles.downloadWrapper}>
        <Button
          onClick={() =>
            handleDownload(
              `https://raw.githubusercontent.com/fifasnipebot/bot/main/Bakers_Bot.zip`,
              "Bakers_Bot.zip"
            )
          }
          label="Download Extension"
        />
      </div>
      {
        isSafari ?       
          <span className={styles.downloadText}>
                Check videos on chrome
          </span> :
          <span className={styles.downloadText}>
            The video covers every step in detail and is very clear. If you are
            stuck, please re-watch the video carefully, it is most likely you are
            missing a step or making a mistake. If you have re-watched the video and
            are still stuck, then feel free to contact us on Instagram
          </span>
      }
      <div>
        <div className={styles.videoWrapper}>
          How to Setup Baker's Bot:
        </div>
        {!isSafari && (
          <video
            src={`${config.video_server}video/setup`}
            width={videoDimensions.width}
            height={videoDimensions.height}
            controls
            controlsList="nodownload"
            playsInline
          ></video>
        )}
      </div>
      <div>
        <div className={styles.videoWrapper}>
          How to use Baker's Bot:
        </div>
        {!isSafari && (
          <video
            src={`${config.video_server}video/use`}
            width={videoDimensions.width}
            height={videoDimensions.height}
            controls
            controlsList="nodownload"
            playsInline
          ></video>
        )}

      </div>
      <div>
        <div className={styles.videoWrapper}>
          How to know what to snipe:
        </div>
        {!isSafari && (
          <video
            src={`${config.video_server}video/snipe`}
            width={videoDimensions.width}
            height={videoDimensions.height}
            controls
            controlsList="nodownload"
            playsInline
          ></video>
        )}

      </div>

{/*       {isSafari && (
        <div>
          <div style={{ padding: "59.84% 0 0 0", position: "relative" }}>
            <iframe
              src="https://player.vimeo.com/video/787017236?h=d8843b8451&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              className={styles.videoiframe}
              title="Tutorial Video"
            ></iframe>
          </div>
          <script src="https://player.vimeo.com/api/player.js"></script>
        </div>
      )} */}
    </div>
  );
}
