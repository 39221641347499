import React from "react";
import styles from "../styles/pages/_videos.module.scss";

import "../styles/components/markdownStyles.scss";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import yuotubeLogo from "../assets/images/youtubeLogo.png";

export default function Videos() {
  const markdown = `# Videos ![Youtube Logo](${yuotubeLogo})
  #### **Still have more questions? Feel free to contact us**
  `;
  const markdownRight = `##### On this page you will find videos of the bot in action as well as the features the bot has.
  &nbsp;  `;

  const video1 = ` 
  &nbsp;  
  &nbsp;  
  ### **How to Purchase**
  [![Video 1](https://img.youtube.com/vi/o_64vhfMMRg/0.jpg)](https://www.youtube.com/watch?v=o_64vhfMMRg)`;

  const video2 = `
  &nbsp;  
  &nbsp;  
  ### **How Baker's Bot works**
  [![Video 1](https://img.youtube.com/vi/abrWskqeJZk/0.jpg)](https://www.youtube.com/watch?v=abrWskqeJZk)
`;

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <div className="row">
          <div className="col-md-6 youtubeLogo">
            <ReactMarkdown children={markdown} remarkPlugins={[remarkGfm]} />
          </div>
          <div className="col-md-6 rightSection">
            <ReactMarkdown
              children={markdownRight}
              remarkPlugins={[remarkGfm]}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 youtubeVideos">
            <ReactMarkdown children={video1} remarkPlugins={[remarkGfm]} />
          </div>
          <div className="col-md-6 youtubeVideos">
            <ReactMarkdown children={video2} remarkPlugins={[remarkGfm]} />
          </div>
        </div>
      </div>
    </div>
  );
}
